import styled from "styled-components";
import { defaultColor } from "src/globalStyle";

export const HeaderTitle = styled.span`
  color: black;
  font-size: 20px;
  font-weight: bold;
`;

export const MainSubTitle = styled.span`
  color: white;
  font-size: 20px;
  font-weight: 500;

  @media (max-width: 835px) {
    font-size: 15px;
    text-align: center;
    // color: black;
  }

  @media (max-width: 410px) {
    font-size: 13px;
    text-align: center;
  }
`;

export const MainTitle = styled.span`
  width: fit-content;
  background-color: white;
  color: black;
  font-family: "GmarketSansMedium";
  font-size: 40px;
  font-weight: 500;
  display: block;
  padding: 10px 80px 5px;
  margin: 15px 0;

  @media (max-width: 835px) {
    font-size: 23px;
    padding: 10px 70px 6px;
  }

  @media (max-width: 410px) {
    font-size: 20px;
    padding: 10px 45px 7px;
  }
`;

export const MainText = styled.span`
  color: ${defaultColor.muted};
  font-family: "GmarketSansBold";
  font-size: 60px;
  font-weight: 700;
  line-height: 70px;
  display: block;

  @media (max-width: 835px) {
    font-size: 30px;
    line-height: 42px;
  }

  @media (max-width: 410px) {
    font-size: 24px;
    line-height: 30px;
  }

  strong {
    color: white;
  }
`;

export const InfoText = styled.span`
  color: ${defaultColor.gray};
  font-size: 18px;
  font-weight: 700;
  margin: 15px 0 5px;

  @media (max-width: 410px) {
    font-size: 13px;
  }
`;

export const InfoTitle = styled.span`
  color: ${defaultColor.gray};
  font-size: 26px;
  font-weight: 700;

  @media (max-width: 410px) {
    font-size: 18px;
  }
`;

export const InfoSecondTitle = styled.span`
  display: block;
  color: ${defaultColor.gray};
  font-family: "NEXONLv1Gothic";
  font-size: 60px;
  font-weight: 700;
  line-height: 78px;

  @media (max-width: 1150px) {
    font-size: 44px;
    line-height: 50px;
  }

  @media (max-width: 410px) {
    font-size: 34px;
    line-height: 40px;
  }
`;

export const InfoSecondText = styled.span`
  display: block;
  color: ${defaultColor.gray};
  font-size: 22px;
  font-weight: 400;
  line-height: 34px;

  @media (max-width: 1150px) {
    font-size: 20px;
  }

  @media (max-width: 410px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const InfoThirdTitle = styled.span`
  display: block;
  color: black;
  font-family: "NEXONLv1Gothic";
  font-size: 56px;
  font-weight: 700;
  line-height: 70px;
  white-space: nowrap;
  text-align: center;

  @media (max-width: 1150px) {
    font-size: 32px;
    line-height: 38px;
  }
`;

export const InfoFourthTitle = styled.span`
  display: block;
  color: ${defaultColor.primary};
  font-family: "NEXONLv1Gothic";
  font-size: 60px;
  font-weight: 700;
  line-height: 78px;
  white-space: nowrap;
  text-align: left;

  @media (max-width: 1150px) {
    font-size: 44px;
    line-height: 50px;
  }

  @media (max-width: 835px) {
    font-size: 38px;
    line-height: 46px;
    text-align: center;
  }
`;

export const InfoThirdText = styled.span`
  display: block;
  color: black;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  line-height: 34px;

  @media (max-width: 1150px) {
    font-size: 18px;
  }

  @media (max-width: 835px) {
    font-size: 16px;
  }

  @media (max-width: 410px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const InfoFourthText = styled.span`
  display: block;
  color: ${defaultColor.warning};
  font-size: 26px;
  font-weight: 400;

  @media (max-width: 835px) {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }
`;

export const LastSectionTitle = styled.span`
  display: block;
  color: ${defaultColor.primary};

  font-family: "NEXONLv1Gothic";
  font-size: 56px;
  font-weight: 700;

  text-align: center;
  margin-bottom: 60px;

  @media (max-width: 835px) {
    font-size: 34px;
    line-height: 40px;
  }
`;

export const InputText = styled.span`
  font-size: 20px;
  color: black;
  position: absolute;
  bottom: 14px;
  right: 22px;
`;
