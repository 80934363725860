import { firebaseDB } from "./firebase"
import { ref, set } from "firebase/database"

class Repository {
	storeInfo(userId, info) {
		const date = String(info.date).slice(0, 10)
		set(ref(firebaseDB, `info/${date}/${userId}`), info)
	}
}
export default Repository
