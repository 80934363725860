import ReactDOM from "react-dom";
import { App } from "./App";
import { GlobalStyle } from "./globalStyle";

ReactDOM.render(
  <>
    <App />
    <GlobalStyle />
  </>,
  document.getElementById("root")
);
