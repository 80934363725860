import React, { FC, useEffect } from "react";
import {
  InfoText,
  InfoTitle,
  InfoSecondTitle,
  InfoSecondText,
  InfoThirdTitle,
  InfoThirdText,
  InfoFourthTitle,
  InfoFourthText,
  SectionButton,
} from "src/components/atoms";
import "animate.css/animate.min.css";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { AnimationOnScroll } from "react-animation-on-scroll";
import styled from "styled-components";
import { defaultColor } from "src/globalStyle";
import { FadeInWhenVisible } from "./api";

const lists = [
  {
    title: "신청자격",
    text: "<strong>4대보험</strong>가입 직장",
  },
  {
    title: "소득조건",
    text: "<strong>월소득</strong><strong>150만원</strong>이상",
  },
  {
    title: "신용등급",
    text: "<strong>1 ~ 7등급</strong>이내",
  },
  {
    title: "연체이력",
    text: "<strong>최근 3개월</strong>이내 X",
  },
];

export const InfoSection: FC = () => {
  return (
    <MainSectionBlock>
      <FirstSection>
        <InnerSection>
          <FadeInWhenVisible>
            <CircleItem>
              <InfoText>최대한도</InfoText>
              <InfoTitle>1억원</InfoTitle>
            </CircleItem>
            <CircleItem>
              <InfoText>이자율</InfoText>
              <InfoTitle>5.9%~</InfoTitle>
            </CircleItem>
            <CircleItem>
              <InfoText>상환기간</InfoText>
              <InfoTitle>최대10년</InfoTitle>
            </CircleItem>
          </FadeInWhenVisible>
        </InnerSection>
      </FirstSection>
      <SecondSection>
        <InnerSection>
          <FadeInWhenVisible
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <div>
              <InfoSecondTitle>근로자희망대출</InfoSecondTitle>
              <InfoSecondTitle>대출이란?</InfoSecondTitle>
              <p />
              <InfoSecondText>
                1. 40개 이상 금융사의 금융 상품 최저금리 비교
              </InfoSecondText>
              <InfoSecondText>2. 비대면으로 당일입금 가능</InfoSecondText>
              <InfoSecondText>
                3. 다중 대출을 한 건으로 통합하여 이자부담 경감 가능
              </InfoSecondText>
            </div>
            <div>
              <img alt="" src="/assets/lowest.gif" width="800" />
            </div>
          </FadeInWhenVisible>
        </InnerSection>
      </SecondSection>
      <ThirdSection>
        <InnerSection>
          <FadeInWhenVisible
            animateOnce
            delay={700}
            animateIn="animate__fadeInUp"
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <InfoThirdTitle>복잡한 금융,</InfoThirdTitle>
              <InfoThirdTitle>근로자희망대출 대출이</InfoThirdTitle>
              <InfoThirdTitle>정리해드립니다</InfoThirdTitle>
              <p />
              <InfoThirdText>
                전화 한통으로 은행 방문없이 최소 절차 진행
              </InfoThirdText>
              <InfoThirdText>
                전문 컨설턴트 상담으로 맞춤형 최저 대출금리 비교
              </InfoThirdText>
              <InfoThirdText>
                신용등급 영향없이 신청부터 입금까지 당일처리 가능
              </InfoThirdText>
            </div>
            <br />
            <div>
              <img
                alt=""
                src="/assets/phone.png"
                width="540"
                style={{ marginLeft: "auto", marginRight: "auto" }}
              />
            </div>
          </FadeInWhenVisible>
        </InnerSection>
      </ThirdSection>
      <FourthSection>
        <InnerSection>
          <FadeInWhenVisible
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
            }}
          >
            <div>
              <InfoFourthTitle>근로자 대상 모바일</InfoFourthTitle>
              <InfoFourthTitle>지원 신청자격</InfoFourthTitle>
              <p />
              <InfoFourthText>개인회생,회복, 파산 이력 신청불가</InfoFourthText>
              <InfoFourthText>신용불량자 신청불가</InfoFourthText>
              <SectionButton
                onClick={() => {
                  window.location.href = "#last_section";
                }}
              >
                가능여부 확인하기
              </SectionButton>
            </div>
          </FadeInWhenVisible>
          <div>
            <CardBox>
              {lists.map((item) => (
                <FadeInWhenVisible>
                  <CardItem>
                    <CardHeader>{item.title}</CardHeader>
                    <CardBody dangerouslySetInnerHTML={{ __html: item.text }} />
                  </CardItem>
                </FadeInWhenVisible>
              ))}
            </CardBox>
          </div>
        </InnerSection>
      </FourthSection>
      <FifthSection>
        <InnerSection>
          <FadeInWhenVisible
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              color: "white",
              // margin: "20px 0",
            }}
          >
            <div>
              <img alt="" src="/assets/check.svg" width="20" />
              4대보험 가입 근로자 우대
            </div>
            <div>
              <img alt="" src="/assets/check.svg" width="20" />
              재직기간 3개월 이상 근로자
            </div>
            <div>
              <img alt="" src="/assets/check.svg" width="20" />
              신용카드 사용자
            </div>
            <div>
              <img alt="" src="/assets/check.svg" width="20" />
              우량직군 (대기업 공무원 등) 우대
            </div>
            <div>
              <img alt="" src="/assets/check.svg" width="20" />
              아파트 및 자동차 (자산) 소유자 우대
            </div>
            <div>
              <img alt="" src="/assets/check.svg" width="20" />
              부채 : 담보 제외, 연봉의 250% 미만
            </div>
            <div>
              <img alt="" src="/assets/check.svg" width="20" />
              제외 : 최근 3개월 이내 연체자
            </div>
          </FadeInWhenVisible>
        </InnerSection>
      </FifthSection>
    </MainSectionBlock>
  );
};

const MainSectionBlock = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FirstSection = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;

  div#motion {
    display: flex;
    gap: 160px;
    justify-content: center;
  }

  @media (max-width: 1150px) {
    div#motion {
      gap: 90px;
    }
  }

  @media (max-width: 835px) {
    height: auto;
    padding: 30px 0;

    div#motion {
      width: 100%;
      align-items: center;
      gap: 9px;

      div {
        width: auto;
        min-width: auto;
        flex: 1;
        border: none;
        background-color: transparent;
      }
    }
  }

  @media (max-width: 410px) {
    div#motion {
      width: 100%;
      align-items: center;
      gap: 9px;

      div {
        width: auto;
        min-width: auto;
        flex: 1;
        border: none;
        background-color: transparent;
      }
    }
  }
`;

const SecondSection = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: white;
  padding: 60px 0;
  position: relative;

  & > div {
    align-items: start;
  }

  #motion {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 835px) {
      flex-direction: column;
    }
  }

  img {
    width: 400px;

    @media (max-width: 1150px) {
      width: 300px;
    }

    @media (max-width: 835px) {
      width: 300px;
    }
  }
`;

const ThirdSection = styled.div`
  width: 100%;
  height: 720px;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: white;
  padding: 110px 0 0;
  position: relative;
  background: #edfffa;

  & > div {
    align-items: flex-end;
  }

  & > div > div > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1 1;

    & > div {
      width: 50%;

      display: flex;
      justify-content: center;

      p {
        margin: 10px;
      }

      &:first-child {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
  }

  @media (max-width: 1150px) {
    height: 600px;
    & > div {
      display: flex;
      flex-direction: column-reverse;
      justify-content: end;
      align-items: center;

      & img {
        top: 37px;

        @media (max-width: 835px) {
          width: 360px;
        }
      }
    }
  }

  @media (max-width: 835px) {
    height: 500px;
  }
  @media (max-width: 410px) {
    height: 450px;
  }
`;

const FourthSection = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: white;
  padding: 100px 0;
  position: relative;

  #motion {
    display: flex;
  }

  & > div {
    align-items: flex-start;
    flex: 1 1;
    margin-top: 60px;

    & > div {
      p {
        margin: 40px;
      }

      &:first-child {
        position: relative;
      }
    }
  }

  @media (max-width: 1150px) {
    & > div {
      flex-direction: column;
      align-items: center;
      margin-top: 0%;

      & > div:first-child {
        width: 80% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        p {
          margin: 10px;
        }

        button {
          margin-top: 30px;
          margin-bottom: 60px;
        }
      }

      & > div:not(:first-child) {
        width: 80%;

        & > div {
          flex-direction: row !important;
          flex-wrap: wrap;
          gap: 20px;

          & > #motion {
            width: 45%;
            & > div {
              flex-direction: column !important;
            }
          }
        }

        div {
          width: 100%;
          flex-direction: column;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
`;

const FifthSection = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: ${defaultColor.primary_dark};
  padding: 100px 0;
  position: relative;

  div#motion {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: white;
    font-size: 20px;
    font-weight: 500;

    @media (max-width: 835px) {
      font-size: 18px;
    }

    @media (max-width: 410px) {
      font-size: 15px;
    }

    img {
      position: relative;
      top: 3px;
      margin-right: 12px;
    }
  }
`;

const InnerSection = styled.div`
  width: 80%;
  max-width: 1080px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CircleItem = styled.div`
  width: 200px;
  height: 200px;
  min-width: 200px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid #3acba7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CardBox = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
`;

const CardItem = styled.div`
  width: 200px;
  height: 220px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px 5px #0000001c;
  border-radius: 20px;
`;

const CardHeader = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffcc99;
  color: black;
  font-size: 20px;

  font-family: "NEXONLv1Gothic";
  font-weight: bold;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const CardBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: 700;
  font-size: 22px;
  line-height: 34px;

  @media (max-width: 410px) {
    font-size: 16px;
    line-height: 20px;
  }

  strong {
    font-size: 30px;

    @media (max-width: 410px) {
      font-size: 20px;
      line-height: 20px;
    }
  }
`;
