import React, { FC } from "react";
import {
  MainTitle,
  MainSubTitle,
  MainText,
  MainButton,
} from "src/components/atoms";
import { AnimationOnScroll } from "react-animation-on-scroll";
import styled from "styled-components";
import { Header } from "../header";
import { defaultColor } from "src/globalStyle";
import { isMobile } from 'react-device-detect';

export const MainSection: FC = () => {
  const backgroundImage = () => {
    if (!isMobile) {
      return (<img src="/assets/main_background_image.png" alt="" />);
    }
  }

  const mainText = () => {
    if (!isMobile) {
      return (<img src="/assets/main_background_image.png" alt="" />);
    }
  }
  return (
    <MainSectionBlock>
      <Header />

      {backgroundImage()}
      <AnimationOnScroll
        animateOnce
        delay={300}
        animateIn="animate__fadeInUp"
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          marginBottom: "105px",
        }}
      >
        <SectionInner style={{}}>
          <MainSubTitle>
            개인 맞춤형 최저 대출금리
          </MainSubTitle>
          <MainTitle>최저 연 5.9% 금리</MainTitle>
          <MainText>
            <strong>
              4대보험 가입자를
              <br /> 위한 근로자
            </strong>
          </MainText>
          <MainText>비대면 금융지원</MainText>
        </SectionInner>
        <SectionInner>
          <p>*6개월 이상 4대보험 가입된 근로자만 신청 가능</p>
          <p>*회생, 회복, 파산 중인 경우 지원대상에서 제외</p>
          <MainButton
            onClick={() => {
              window.location.href = "#last_section";
            }}
          >
            가능여부 확인하기
          </MainButton>
        </SectionInner>
      </AnimationOnScroll>
    </MainSectionBlock>
  );
};

const MainSectionBlock = styled.div`
  width: 80%;
  max-width: 1080px;
  height: 100vh;
  max-height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 160px;
  background: ${defaultColor.primary};
  position: relative;

  & > img {
    width: 600px;
    position: absolute;
    right: 10%;
    bottom: 0;
  }

  & > div:last-child {
    @media (max-width: 835px) {
      justify-content: center !important;
      margin-bottom: 65px !important;
    }

    @media (max-width: 410px) {
      justify-content: center !important;
      margin-bottom: 65px !important;
    }
  }
`;

const MainImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    // url("/assets/main_background_image.png") no-repeat center center/contain;
  background-size: cover;
  z-index: -1;
  left: 0;
  right: 0;
`;

const SectionInner = styled.div`
  width: 100%;
  max-width: 1080px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  p {
    color: ${defaultColor.muted};
    margin: 3px 0;
    font-size: 18px;

    @media (max-width: 835px) {
      font-size: 12px;
    }
  }

  @media (max-width: 835px) {
    align-items: center;
  }
`;
